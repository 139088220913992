import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  useTheme,
} from '@mui/material';
import { motion } from 'framer-motion';
import LockIcon from '@mui/icons-material/Lock';
import ChatIcon from '@mui/icons-material/Chat';
import StorageIcon from '@mui/icons-material/Storage';
import SecurityIcon from '@mui/icons-material/Security';
import GroupsIcon from '@mui/icons-material/Groups';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useNavigate } from 'react-router-dom';

const ServiceCard = ({ icon, title, description, features }: any) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <Card
      elevation={0}
      sx={{
        height: '100%',
        p: 2,
        '&:hover': {
          transform: 'translateY(-8px)',
          transition: 'transform 0.3s ease-in-out',
          boxShadow: '0 8px 24px rgba(0,0,0,0.1)',
        },
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          {icon}
          <Typography variant="h5" sx={{ mt: 2, mb: 2, fontWeight: 'bold', color: 'primary.main' }}>
            {title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, color: 'text.secondary' }}>
            {description}
          </Typography>
          <Box sx={{ mt: 'auto' }}>
            {features.map((feature: string, index: number) => (
              <Typography
                key={index}
                variant="body2"
                sx={{
                  mb: 1,
                  display: 'flex',
                  alignItems: 'center',
                  '&:before': {
                    content: '"•"',
                    mr: 1,
                    color: 'primary.main',
                  },
                }}
              >
                {feature}
              </Typography>
            ))}
          </Box>
        </Box>
      </CardContent>
    </Card>
  </motion.div>
);

const ServicesPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const services = [
    {
      icon: <SecurityIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />,
      title: 'Private Messaging',
      description: 'End-to-end encrypted messaging with zero-knowledge proof verification.',
      features: [
        'Zero-knowledge encrypted communications',
        'Secure file sharing',
        'Self-destructing messages',
        'Anonymous messaging options'
      ]
    },
    {
      icon: <ChatIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />,
      title: 'Decentralized Social Feed',
      description: 'Share content with complete control over your data and privacy.',
      features: [
        'Content ownership rights',
        'Customizable privacy settings',
        'Decentralized content storage',
        'Monetization options'
      ]
    },
    {
      icon: <GroupsIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />,
      title: 'Private Communities',
      description: 'Create and join communities with verified privacy guarantees.',
      features: [
        'Private group creation',
        'Member verification',
        'Secure voting mechanisms',
        'Resource sharing'
      ]
    },
    {
      icon: <StorageIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />,
      title: 'Decentralized Storage',
      description: 'Your data, stored securely across the blockchain network.',
      features: [
        'Distributed content storage',
        'Data encryption',
        'Content backup',
        'Access control'
      ]
    },
    {
      icon: <LockIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />,
      title: 'Identity Management',
      description: 'Control your digital identity with zero-knowledge verification.',
      features: [
        'Anonymous authentication',
        'Credential management',
        'Identity verification',
        'Privacy controls'
      ]
    },
    {
      icon: <AccountBalanceWalletIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />,
      title: 'Digital Assets',
      description: 'Manage and trade digital assets within the platform.',
      features: [
        'Token creation',
        'Asset trading',
        'NFT support',
        'Secure transactions'
      ]
    }
  ];

  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography
            variant="h2"
            align="center"
            sx={{ mb: 2, color: 'primary.main', fontWeight: 'bold' }}
          >
            Our Services
          </Typography>
          <Typography
            variant="h5"
            align="center"
            sx={{ mb: 8, color: 'text.secondary', maxWidth: '800px', mx: 'auto' }}
          >
            Experience the future of social networking with our comprehensive suite of
            privacy-focused, blockchain-powered services.
          </Typography>
        </motion.div>

        <Grid container spacing={4}>
          {services.map((service, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <ServiceCard {...service} />
            </Grid>
          ))}
        </Grid>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <Box
            sx={{
              mt: 8,
              p: 6,
              bgcolor: 'primary.main',
              borderRadius: 4,
              color: 'white',
              textAlign: 'center',
            }}
          >
            <Typography variant="h4" sx={{ mb: 3, fontWeight: 'bold' }}>
              Ready to Get Started?
            </Typography>
            <Typography variant="body1" sx={{ mb: 4, maxWidth: '600px', mx: 'auto' }}>
              Join our platform today and experience the next generation of social networking
              with uncompromising privacy and security.
            </Typography>
            <Button
              variant="contained"
              size="large"
              sx={{
                bgcolor: 'white',
                color: 'primary.main',
                '&:hover': {
                  bgcolor: 'grey.100',
                },
              }}
              onClick={() => navigate('/login')}
            >
              Start Now
            </Button>
          </Box>
        </motion.div>
      </Container>
    </Box>
  );
};

export default ServicesPage; 