import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Layout/Navbar';
import Login from './components/Auth/Login';
import HomePage from './components/Home/HomePage';
import AboutPage from './components/About/AboutPage';
import ServicesPage from './components/Services/ServicesPage';
import ContactPage from './components/Contact/ContactPage';

const theme = createTheme({
  typography: {
    fontFamily: '"Roboto Mono", monospace',
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
      letterSpacing: '0.02em',
      textShadow: '0 0 10px rgba(57, 255, 20, 0.5)',
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 600,
      letterSpacing: '0.02em',
      textShadow: '0 0 8px rgba(57, 255, 20, 0.4)',
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 600,
      letterSpacing: '0.02em',
      textShadow: '0 0 6px rgba(57, 255, 20, 0.3)',
    },
    body1: {
      fontSize: '1rem',
      letterSpacing: '0.03em',
      lineHeight: 1.6,
      color: 'rgba(57, 255, 20, 0.9)',
    },
    body2: {
      fontSize: '0.875rem',
      letterSpacing: '0.03em',
      lineHeight: 1.6,
      color: 'rgba(57, 255, 20, 0.7)',
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#1DB954', // Spotify-style green - less harsh than neon
      light: '#1ED760',
      dark: '#169C46',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#00B4D8', // Ocean blue - calming and trustworthy
      light: '#48CAE4',
      dark: '#0096C7',
      contrastText: '#FFFFFF',
    },
    background: {
      default: '#121212', // Soft black - easier on eyes than pure black
      paper: '#181818', // Slightly lighter for cards/elements
    },
    text: {
      primary: '#FFFFFF',
      secondary: 'rgba(255, 255, 255, 0.7)',
    },
    error: {
      main: '#EF476F', // Softer red for errors
      light: '#F27A98',
      dark: '#D13860',
    },
    success: {
      main: '#06D6A0', // Mint green for success states
      light: '#39E0B3',
      dark: '#05BF8E',
    },
    warning: {
      main: '#FFD166', // Warm yellow for warnings
      light: '#FFDC85',
      dark: '#E5BC5C',
    },
    info: {
      main: '#118AB2', // Cool blue for information
      light: '#14A2D1',
      dark: '#0F7A9B',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          fontSize: '1rem',
          padding: '0.5rem 1.5rem',
          transition: 'all 0.2s ease-in-out',
          '&:hover': {
            transform: 'translateY(-1px)',
          },
        },
        contained: {
          backgroundColor: '#1DB954',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#1ED760',
          },
        },
        outlined: {
          borderColor: '#1DB954',
          color: '#1DB954',
          borderWidth: 2,
          '&:hover': {
            borderColor: '#1ED760',
            backgroundColor: 'rgba(29, 185, 84, 0.08)',
          },
        },
        text: {
          color: '#1DB954',
          '&:hover': {
            backgroundColor: 'rgba(29, 185, 84, 0.08)',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#181818',
          borderRadius: 12,
          transition: 'all 0.2s ease-in-out',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          '&:hover': {
            backgroundColor: '#202020',
            transform: 'translateY(-4px)',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#121212',
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#181818',
          backgroundImage: 'none',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'rgba(255, 255, 255, 0.04)',
            '& fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.23)',
            },
            '&:hover fieldset': {
              borderColor: '#1DB954',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#1DB954',
            },
          },
          '& .MuiInputLabel-root': {
            color: 'rgba(255, 255, 255, 0.7)',
            '&.Mui-focused': {
              color: '#1DB954',
            },
          },
          '& .MuiInputBase-input': {
            color: '#FFFFFF',
          },
        },
      },
    },
  },
});

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </ThemeProvider>
    </Router>
  );
}

export default App; 