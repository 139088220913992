import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Paper,
  useTheme,
} from '@mui/material';
import { motion } from 'framer-motion';

const AboutPage = () => {
  const theme = useTheme();

  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="lg">
        {/* Vision Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography
            variant="h2"
            align="center"
            sx={{ mb: 6, color: 'primary.main', fontWeight: 'bold' }}
          >
            About Prometheus Labs
          </Typography>
          <Typography
            variant="h5"
            align="center"
            sx={{ mb: 8, color: 'text.secondary', maxWidth: '800px', mx: 'auto' }}
          >
            We're building the next generation of social media, powered by blockchain technology
            and secured by zero-knowledge proofs.
          </Typography>
        </motion.div>

        {/* Mission Section */}
        <Grid container spacing={4} sx={{ mb: 8 }}>
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <Paper elevation={0} sx={{ p: 4, height: '100%', bgcolor: 'background.default' }}>
                <Typography variant="h4" sx={{ mb: 3, color: 'primary.main', fontWeight: 'bold' }}>
                  Our Mission
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Prometheus Labs aims to revolutionize how people interact online by creating a truly
                  decentralized social media platform that puts privacy and user ownership first.
                </Typography>
                <Typography variant="body1">
                  We believe that social media should be free from central control, protect user
                  privacy, and give users true ownership of their data and content.
                </Typography>
              </Paper>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <Paper elevation={0} sx={{ p: 4, height: '100%', bgcolor: 'background.default' }}>
                <Typography variant="h4" sx={{ mb: 3, color: 'primary.main', fontWeight: 'bold' }}>
                  Our Vision
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  We envision a future where social media is truly in the hands of its users,
                  protected by advanced cryptography and powered by blockchain technology.
                </Typography>
                <Typography variant="body1">
                  Through zero-knowledge proofs and blockchain technology, we're creating a platform
                  that ensures privacy while maintaining transparency and trust.
                </Typography>
              </Paper>
            </motion.div>
          </Grid>
        </Grid>

        {/* Technology Section */}
        <Box sx={{ mb: 8 }}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <Typography variant="h3" sx={{ mb: 4, color: 'primary.main', fontWeight: 'bold' }}>
              Our Technology
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Paper elevation={0} sx={{ p: 3, height: '100%', bgcolor: 'background.default' }}>
                  <Typography variant="h5" sx={{ mb: 2, color: 'primary.main' }}>
                    Zero-Knowledge Proofs
                  </Typography>
                  <Typography variant="body1">
                    Our platform utilizes advanced ZK-SNARKs to enable private transactions and
                    interactions while maintaining network integrity and trust.
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper elevation={0} sx={{ p: 3, height: '100%', bgcolor: 'background.default' }}>
                  <Typography variant="h5" sx={{ mb: 2, color: 'primary.main' }}>
                    Blockchain Infrastructure
                  </Typography>
                  <Typography variant="body1">
                    Built on a robust blockchain foundation that ensures decentralization,
                    immutability, and transparent governance.
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper elevation={0} sx={{ p: 3, height: '100%', bgcolor: 'background.default' }}>
                  <Typography variant="h5" sx={{ mb: 2, color: 'primary.main' }}>
                    Decentralized Storage
                  </Typography>
                  <Typography variant="body1">
                    User content is stored on decentralized networks, ensuring data persistence
                    and resistance to censorship.
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </motion.div>
        </Box>

        {/* Future Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <Paper 
            elevation={0} 
            sx={{ 
              p: 6, 
              bgcolor: theme.palette.primary.main,
              color: 'white',
              borderRadius: 4,
            }}
          >
            <Typography variant="h3" sx={{ mb: 3, fontWeight: 'bold' }}>
              The Future of Social Media
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, fontSize: '1.1rem' }}>
              We're not just building another social media platform - we're creating the
              infrastructure for the next generation of social interaction.
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '1.1rem' }}>
              Join us in building a future where privacy, security, and user ownership are not
              just features, but fundamental rights.
            </Typography>
          </Paper>
        </motion.div>
      </Container>
    </Box>
  );
};

export default AboutPage; 