import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  useTheme,
  Stack,
} from '@mui/material';
import { motion } from 'framer-motion';
import SecurityIcon from '@mui/icons-material/Security';
import GroupIcon from '@mui/icons-material/Group';
import StorageIcon from '@mui/icons-material/Storage';
import { useNavigate } from 'react-router-dom';
import RotatingCube from './RotatingCube';

const FeatureCard = ({ icon, title, description }: any) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <Card 
      elevation={0}
      sx={{ 
        height: '100%',
        backgroundColor: 'transparent',
        '&:hover': {
          transform: 'translateY(-8px)',
          transition: 'transform 0.3s ease-in-out',
        },
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          {icon}
          <Typography variant="h5" component="h3" sx={{ mt: 2, mb: 2, fontWeight: 'bold', color: 'primary.main' }}>
            {title}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {description}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  </motion.div>
);

const HomePage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box>
      {/* Hero Section */}
      <Box
        sx={{
          minHeight: '90vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'background.default',
          pt: 8,
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <RotatingCube />
        <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography 
              variant="h1" 
              component="h1" 
              align="center"
              sx={{ 
                color: 'primary.main',
                mb: 2,
                fontWeight: 'bold'
              }}
            >
              Prometheus Labs
            </Typography>
            <Typography 
              variant="h2" 
              component="h2" 
              align="center"
              sx={{ 
                color: 'secondary.main',
                mb: 4 
              }}
            >
              Revolutionizing Social Media with Zero-Knowledge Blockchain
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 6 }}>
              <Button 
                variant="contained" 
                size="large"
                onClick={() => navigate('/services')}
              >
                Explore Platform
              </Button>
              <Button 
                variant="outlined" 
                size="large"
                onClick={() => navigate('/about')}
              >
                Learn More
              </Button>
            </Box>
          </motion.div>
        </Container>
      </Box>

      {/* Features Section */}
      <Box sx={{ py: 8, bgcolor: 'white' }}>
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            align="center"
            sx={{ mb: 6, color: 'primary.main', fontWeight: 'bold' }}
          >
            Why Choose Prometheus Labs?
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <FeatureCard
                icon={<SecurityIcon sx={{ fontSize: 60, color: theme.palette.primary.main }} />}
                title="Zero-Knowledge Privacy"
                description="Our platform leverages cutting-edge zero-knowledge proofs to ensure your data remains private while maintaining transparency and trust in the network."
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FeatureCard
                icon={<GroupIcon sx={{ fontSize: 60, color: theme.palette.primary.main }} />}
                title="Decentralized Social"
                description="Experience social media as it should be - owned by users, free from central control, and built on blockchain technology for true data ownership."
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FeatureCard
                icon={<StorageIcon sx={{ fontSize: 60, color: theme.palette.primary.main }} />}
                title="Blockchain Foundation"
                description="Built on robust blockchain infrastructure ensuring immutability, transparency, and decentralized governance for all platform operations."
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Technology Stack Section */}
      <Box sx={{ py: 8, bgcolor: 'background.default' }}>
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            align="center"
            sx={{ mb: 6, color: 'primary.main', fontWeight: 'bold' }}
          >
            Our Technology Stack
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Stack spacing={2}>
                  <Typography variant="h5" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                    Zero-Knowledge Infrastructure
                  </Typography>
                  <Typography variant="body1">
                    • Advanced ZK-SNARKs implementation for privacy-preserving transactions
                  </Typography>
                  <Typography variant="body1">
                    • Secure proof generation and verification systems
                  </Typography>
                  <Typography variant="body1">
                    • Efficient zero-knowledge protocols for social interactions
                  </Typography>
                </Stack>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Stack spacing={2}>
                  <Typography variant="h5" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                    Blockchain Integration
                  </Typography>
                  <Typography variant="body1">
                    • Custom smart contracts for social interactions
                  </Typography>
                  <Typography variant="body1">
                    • Decentralized storage solutions for user content
                  </Typography>
                  <Typography variant="body1">
                    • Layer 2 scaling solutions for optimal performance
                  </Typography>
                </Stack>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Call to Action */}
      <Box sx={{ py: 8, bgcolor: 'primary.main' }}>
        <Container maxWidth="md">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Typography
              variant="h4"
              align="center"
              sx={{ color: 'white', mb: 3, fontWeight: 'bold' }}
            >
              Ready to Join the Future of Social Media?
            </Typography>
            <Typography
              variant="h6"
              align="center"
              sx={{ color: 'white', mb: 4, opacity: 0.9 }}
            >
              Be part of the revolution in decentralized social networking
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
              <Button
                variant="contained"
                size="large"
                sx={{ 
                  bgcolor: 'white',
                  color: 'primary.main',
                  '&:hover': {
                    bgcolor: 'grey.100',
                  }
                }}
                onClick={() => navigate('/login')}
              >
                Get Started
              </Button>
              <Button
                variant="outlined"
                size="large"
                sx={{ 
                  color: 'white',
                  borderColor: 'white',
                  '&:hover': {
                    borderColor: 'grey.100',
                    bgcolor: 'rgba(255,255,255,0.1)',
                  }
                }}
                onClick={() => navigate('/contact')}
              >
                Contact Us
              </Button>
            </Box>
          </motion.div>
        </Container>
      </Box>
    </Box>
  );
};

export default HomePage; 