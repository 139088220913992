import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { Box } from '@mui/material';

const RotatingCube: React.FC = () => {
  const mountRef = useRef<HTMLDivElement>(null);
  const sceneRef = useRef<THREE.Scene | null>(null);
  const cameraRef = useRef<THREE.PerspectiveCamera | null>(null);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const cubesRef = useRef<THREE.Mesh[]>([]);

  useEffect(() => {
    if (!mountRef.current) return;

    // Scene setup with darker background
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0x000000); // Pure black background
    sceneRef.current = scene;

    // Camera setup
    const camera = new THREE.PerspectiveCamera(
      75,
      mountRef.current.clientWidth / mountRef.current.clientHeight,
      0.1,
      1000
    );
    camera.position.z = 35;
    camera.position.y = 0;
    camera.position.x = 0;
    cameraRef.current = camera;

    // Renderer setup
    const renderer = new THREE.WebGLRenderer({ 
      antialias: true,
      alpha: false,
    });
    renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
    mountRef.current.appendChild(renderer.domElement);
    rendererRef.current = renderer;

    // Matrix green color
    const matrixGreen = 0x00ff00; // Bright matrix green
    const matrixGreenDark = 0x008000; // Darker green for depth

    // Create cube with matrix green material
    const createCube = (position: THREE.Vector3) => {
      const geometry = new THREE.BoxGeometry(1, 1, 1);
      const material = new THREE.MeshPhongMaterial({
        color: matrixGreen,
        transparent: true,
        opacity: 0.9,
        shininess: 100,
        emissive: matrixGreen,
        emissiveIntensity: 0.8,
        side: THREE.DoubleSide,
      });
      const cube = new THREE.Mesh(geometry, material);
      cube.position.copy(position);
      scene.add(cube);
      return cube;
    };

    // Create connecting lines with matrix green glow
    const createLine = (start: THREE.Vector3, end: THREE.Vector3) => {
      const points = [];
      points.push(start);
      points.push(end);
      const geometry = new THREE.BufferGeometry().setFromPoints(points);
      const material = new THREE.LineBasicMaterial({ 
        color: matrixGreen,
        transparent: true,
        opacity: 0.5,
      });
      const line = new THREE.Line(geometry, material);
      scene.add(line);
      return line;
    };

    const cubes: THREE.Mesh[] = [];
    const lines: THREE.Line[] = [];
    const numCubes = 45;

    // Create cubes in random positions
    for (let i = 0; i < numCubes; i++) {
      const theta = Math.random() * Math.PI * 2;
      const phi = Math.acos((Math.random() * 2) - 1);
      const radius = 15 + Math.random() * 25;

      const position = new THREE.Vector3(
        radius * Math.sin(phi) * Math.cos(theta),
        (radius * 0.6) * Math.sin(phi) * Math.sin(theta),
        radius * Math.cos(phi)
      );
      
      const cube = createCube(position);
      cubes.push(cube);
    }

    // Connect nearby cubes with lines
    const maxDistance = 15;
    for (let i = 0; i < cubes.length; i++) {
      let connections = 0;
      const maxConnections = 4 + Math.floor(Math.random() * 3);
      
      for (let j = 0; j < cubes.length; j++) {
        if (i !== j && connections < maxConnections) {
          const distance = cubes[i].position.distanceTo(cubes[j].position);
          if (distance < maxDistance) {
            lines.push(createLine(cubes[i].position, cubes[j].position));
            connections++;
          }
        }
      }
    }

    cubesRef.current = cubes;

    // Add lights for enhanced matrix effect
    const ambientLight = new THREE.AmbientLight(0x000000, 0.1);
    scene.add(ambientLight);

    const pointLight1 = new THREE.PointLight(matrixGreen, 2.5);
    pointLight1.position.set(15, 15, 15);
    scene.add(pointLight1);

    const pointLight2 = new THREE.PointLight(matrixGreen, 2.5);
    pointLight2.position.set(-15, -15, -15);
    scene.add(pointLight2);

    // Animation
    let time = 0;
    const animate = () => {
      requestAnimationFrame(animate);
      time += 0.002;

      cubes.forEach((cube, index) => {
        // Gentle rotation
        cube.rotation.x += 0.002;
        cube.rotation.y += 0.002;

        // Organic floating motion
        const offset = index * 0.3;
        const radiusFromCenter = cube.position.length();
        const speedFactor = 1 - (radiusFromCenter / 40);

        cube.position.y += Math.sin(time + offset) * 0.015 * speedFactor;
        cube.position.x += Math.cos(time + offset) * 0.01 * speedFactor;
        cube.position.z += Math.sin(time * 0.8 + offset) * 0.01 * speedFactor;

        // Enhanced pulsing glow effect
        const material = cube.material as THREE.MeshPhongMaterial;
        material.emissiveIntensity = 0.8 + Math.sin(time * 2 + offset) * 0.4;
        material.opacity = 0.8 + Math.sin(time * 2 + offset) * 0.2;
      });

      // Update all connecting lines with pulsing opacity
      lines.forEach((line, index) => {
        const positions = line.geometry.attributes.position;
        const startCube = cubes[Math.floor(index / (cubes.length - 1))];
        const endCube = cubes[(index % (cubes.length - 1)) + 1];
        
        positions.setXYZ(0, startCube.position.x, startCube.position.y, startCube.position.z);
        positions.setXYZ(1, endCube.position.x, endCube.position.y, endCube.position.z);
        positions.needsUpdate = true;

        // Enhanced pulsing line opacity
        const material = line.material as THREE.LineBasicMaterial;
        material.opacity = 0.3 + Math.sin(time * 2 + index * 0.1) * 0.2;
      });

      renderer.render(scene, camera);
    };

    animate();

    // Handle resize
    const handleResize = () => {
      if (!mountRef.current || !cameraRef.current || !rendererRef.current) return;

      const width = mountRef.current.clientWidth;
      const height = mountRef.current.clientHeight;

      cameraRef.current.aspect = width / height;
      cameraRef.current.updateProjectionMatrix();

      rendererRef.current.setSize(width, height);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      mountRef.current?.removeChild(renderer.domElement);
      cubes.forEach(cube => {
        scene.remove(cube);
        cube.geometry.dispose();
        (cube.material as THREE.Material).dispose();
      });
      lines.forEach(line => {
        scene.remove(line);
        line.geometry.dispose();
        (line.material as THREE.Material).dispose();
      });
      renderer.dispose();
    };
  }, []);

  return (
    <Box
      ref={mountRef}
      sx={{
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 0,
        opacity: 1,
        pointerEvents: 'none',
        bgcolor: '#000000',
      }}
    />
  );
};

export default RotatingCube; 